// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  auth: {
    login: `تسجيل الدخول`,
    welcome: `مرحبا بك مرة أخرى`,
    email: `البريد الإلكتروني`,
    password: `كلمه السر`,
  },
  users: {
    title: `المستخدمين`,
    name: `اسم`,
    email: `البريد الإلكتروني`,
    role: `وظيفة`,
    status: `الحالة`,
    code: `الرمز`,
  },
  services: {
    title: `الخدمات`,
    titles: `العنوان`,
    description: `وصف`,
    status: `الحالة`,
    totalCount: `عدد الحجوزات`,
  },
  partners: {
    title: `الشركاء`,
    titles: `العنوان`,
    description: `وصف`,
    status: `الحالة`,
    link: `رابط`,
  },
  brands: {
    title: `العلامات التجارية`,
    titles: `العنوان`,
    description: `وصف`,
    status: `الحالة`,
  },
  messages: {
    title: `رسالة`,
    name: `اسم`,
    phone: `هاتف`,
    email: `البريد الإلكتروني`,
    message: `رسالة`,
    reply: `رد`,
    true: `تم الرد`,
    false: `لم يتم الرد`,
    null: `الكل`,
  },
  events: {
    title: `الأحداث`,
    titles: `الاسم`,
    unique_number_qrcode: `رمز الاستجابة السريعة`,
    description: `وصف`,
    location: `الموقع`,
    locationDescription: `وصف الموقع`,
    locationLatitude: `خط العرض`,
    locationLongitude: `خط الطول`,
    slogan: `شعار`,
    status: `الحالة`,
    date: `تاريخ`,
    time: `الوقت`,
    link: `رابط`,
    startAt: `بداية في`,
    endAt: `ينتهي في`,
    extendTo: `تمديد إلى`,
    capacity: `السعة`,
    allowedTickets: `التذاكر المسموح بها لكل فترة`,
    allowedVIPTickets: `التذاكر VIP المسموح بها لكل فترة`,
    allowedEvery: `زمن كل فترة بالدقائق`,
    ticketPrice: `سعر التذكرة شامل الضريبة`,
    vipTicketPrice: `سعر التذكرة VIP شامل الضريبة`,
    ticketValidityPeriod: `فترة صلاحية التذكرة بالدقائق`,
    helpServicePrice: `سعر خدمة المساعدة شامل الضريبة`,
    selectDate: `حدد الأيام`,
    day: `يوم`,
    period_number: `عدد الفترات`,
    days_number: `عدد الأيام`,
    ticket_count: `عدد التذاكر`,
    vip_ticket_count: `عدد التذاكر VIP`,
    reservation_count: `عدد الحجوزات`,
    name: `اسم`,
    email: `البريد الإلكتروني`,
    phone: `هاتف`,
    day_periods: `فترات اليوم`,
    reservoirs: `الحجوزات`,
    days: `الأيام`,
    tickets: `التذاكر`,
    vipTickets: `التذاكر VIP`,
    unique_number: `رقم`,
    alertIn: `تنبيه عند مرور عدد الدقائق`,
    created_at: `تاريخ الإنشاء`,
    unvalid_at: `وقت الإنتهاء`,
    alert_at: `تاريخ التنبيه`,
    note: `ملاحظة`,
    note_edit: `تعديل الملاحظة`,
    helpServiceNote: `ملاحظة خدمة المساعدة`,
    tax: `الضريبة`,
    address: `العنوان`,
    howDidYouKnowUs: `كيف عرفت عنا`,
    extendTo_error: `يجب أن يكون تاريخ التمديد بعد تاريخ النهاية`,
    to_time_error: `يجب أن يكون وقت النهاية بعد وقت البداية`,
    extend_day_period: `تمديد فترات اليوم`,
    reservation_date: `تاريخ الحجز`,
    children: `عدد الأطفال`,
    from: `من`,
    to: `إلى`,
    newDate: `تاريخ جديد`,
    newTime: `وقت جديد`,
    edit_reservation: `تعديل الحجز`,
    edit_periods_tickets: `تعديل تذاكر `,
    edit_periods_tickets_validity: `تعديل فترة تذاكر `,
  },
  contactInfo: {
    email: `البريد الإلكتروني`,
    phone: `هاتف`,
    address: `عنوان`,
    whatsapp: `واتساب`,
    facebook: `فيسبوك`,
    instagram: `انستغرام`,
    twitter: `تويتر`,
    linkedin: `لينكد إن`,
    youtube: `يوتيوب`,
    tiktok: `تيك توك`,
  },
  blogPost: {
    title: `مقالات`,
    details: `تفاصيل`,
    titles: `العنوان`,
    description: `وصف`,
    content: `محتوى`,
    status: `الحالة`,
    publish: `نشر`,
    comments: `تعليقات`,
    metaTitle: `عنوان الفوقية`,
    metaDescription: `ميتا الوصف`,
    metaKeywords: `ميتا الكلمات الرئيسية`,
    recentPosts: `المشاركات الأخيرة`,
  },
  hdykus: {
    title: `خيارات كيف تعرفنا`,
    titles: `العنوان`,
    description: `وصف`,
    status: `الحالة`,
  },
  common: {
    true: `فعال`,
    false: `معطل`,
    create: `انشاء`,
    cerate: `انشاء`,
    extend: `تمديد`,
    cancel: `إلغاء`,
    save: `حفظ`,
    edit: `تعديل`,
    edit_periods: `تعديل الفترات `,
    delete: `تعطيل`,
    hide: `إخفاء`,
    restore: `اعادة تفعيل`,
    deleteConfirm: `هل أنت متأكد أنك تريد تعطيل هذا؟`,
    restoreConfirm: `هل أنت متأكد أنك تريد اعادة تفعيل هذا؟`,
    view: `عرض`,
    send: `إرسال`,
    confirm: `ارسال التذاكر`,
    view_reservation: ` حجوزات مؤكدة`,
    view_deleted_reservations: `حجوزات غير مؤكدة`,
    view_cancelled_reservations: `حجوزات ملغاة`,
    actions: `الإجراءات`,
    search: `بحث...`,
    all: `الكل`,
    export: `تصدير`,
    active: `فعال`,
    banned: `محظور`,
    ADMIN: `مشرف`,
    SUDO: `ادمن`,
    AGENT: `منظم داخلي`,
    SECURITY: `منظم بوابة`,
    USER: `مستخدم`,
    ANALYST: `محلل`,
    ACCOUNTER: `كاشير`,
    ACTIVE: `فعال`,
    INACTIVE: `محظور`,
    sunday: `الأحد`,
    monday: `الإثنين`,
    tuesday: `الثلاثاء`,
    wednesday: `الأربعاء`,
    thursday: `الخميس`,
    friday: `الجمعة`,
    saturday: `السبت`,
    apply: `تطبيق`,
    select_date_range: `حدد نطاق التاريخ`,
    date_range_error: `يجب أن يكون تاريخ البداية أقل من تاريخ النهاية`,
    is_required: `مطلوب`,
    details: `تفاصيل`,
    VALID: `بدات`,
    SCANNED: `ممسوحة`,
    EXPIRED: `انتهت `,
    PREVAL: `قاربت على البدايه`,
    PREEXP: `قاربت على النهاية`,
    edit_ticket_content: `محتوى التذكرة`,
    extend_all_days_periods: `تمديد جميع فترات الأيام`,
    date: `تاريخ`,
    cancel_reservation: `إلغاء الحجز`,
    cancel_reservation_confirm: `هل أنت متأكد أنك تريد إلغاء هذا؟`,
    cancel_reservation_success: `تم إلغاء الحجز بنجاح`,
    total_reservations: `إجمالي الحجوزات`,
    total_tickets: `إجمالي التذاكر`,
    vip_total_tickets: ` VIP إجمالي التذاكر`,
    total_edited_reservations: `إجمالي الحجوزات المعدلة`,
    total_opts: `وصل لهم رسايل OTP`,
    reservations: `الحجوزات`,
    Done: `حجوزات مؤكدة`,
    Cancel: `حجوزات غير مؤكدة`,
    Edit: `حجوزات معدلة`,
    palt: "تذاكر ماى تيبل",
    theChefz: "تذاكر ذا شيفز",
    nbk: "تذاكر وى بوك",
    musafer: "تذاكر المسافر",
    plat_list: "تذاكر بلاتنيوم ",
    smar: "تذاكر سمار",
  },
  coupons: {
    title: `كوبون`,
    titles: `العنوان`,
    code: `الرمز`,
    type: `النوع`,
    value: `القيمة`,
    useCount: `عدد الاستخدامات`,
    status: `الحالة`,
    PERCENTAGE: `نسبة مئوية`,
    AMOUNT: `مبلغ`,
    elraghy: `الراجحي`,
    used_elraghy: `استخدمت من الراجحي`,
    qtaf: `قطاف`,
    used_qtaf: `استخدمت من قطاف`,
  },
  neighborhoods: {
    title: `الأحياء`,
    titles: `العنوان`,
    description: `وصف`,
    status: `الحالة`,
  },
  errors: {
    "Reservation is scanned": ` تم مسح التذكرة ولا يمكن تعديل الحجز`,
  },
  dashboard: `لوحة التحكم`,
  app: `الاحصائيات`,
  app_tickets: "احصائيات التذاكر",
  app_smar: "احصائيات سمار",
  user: `المستخدمين`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  post: `مقالات`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `عام`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level: `مستوى القائمة `,
  menu_level_2a: `مستوى القائمة 2 أ`,
  menu_level_2b: `مستوى القائمة 2 ب`,
  menu_level_3a: `مستوى القائمة 3 أ`,
  menu_level_3b: `مستوى القائمة 3 ب`,
  menu_level_4a: `مستوى القائمة 4 أ`,
  menu_level_4b: `مستوى القائمة 4 ب`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  item_external_link: `رابط خارجي للمادة`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
  service: `الخدمات`,
  partner: `شريك`,
  coupon: `كوبون`,
  brand: `علامة تجارية`,
  message: `رسالة`,
  contact_us: `اتصل بنا`,
  event: `حدث`,
  neighborhood: `حي`,
  hdyku: `خيارات كيف تعرفنا`,
  permissionDenied: `رفض الإذن`,
  permissionDeniedDescription: `ليس لديك الإذن للوصول إلى هذه الصفحة`,
};

export default ar;
